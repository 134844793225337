<template>
  <div class="filter-men text-left f-w3" id="filter-men">
    <template v-if="isLoading">
      <AppLoading />
    </template>
    <template v-else>
      <div class="d-flex justify-content-between align-items-center play-area">
        <label class="play-area-label f-w6" for="">居住地</label>
        <p class="play-area__p">※複数選択可</p>
      </div>
      <SelectMultiFilterCast
        :options="commonTutorials.prefectures"
        v-model="areaValue"
        @select-option="areaValue = $event"
      />
      <div class="age">
        <p class="age-label f-w6" for="">年齢</p>
        <div class="d-flex justify-content-between align-items-center">
          <div
            class="score position-relative"
            :class="isShowAgeLower ? 'active' : ''"
            :tabindex="tabindex"
            @blur="isShowAgeLower = false"
          >
            <div
              class="drop-down d-flex align-items-center"
              @click="isShowAgeLower = !isShowAgeLower"
            >
              <span class="score__text">{{ ageLower }}</span>
            </div>
            <div class="position-absolute score-option">
              <div
                class="score-option__items"
                v-for="(age, index) in listAgeLower"
                :class="ageLower == listAgeLower[index] ? 'active' : ''"
                :key="index"
                @click="chooseAgeLower(index)"
              >
                {{ listAgeLower[index] }}
              </div>
            </div>
          </div>
          <span class="f-w6 score__span">~</span>
          <div
            class="score position-relative"
            :class="isShowAgeUpper ? 'active' : ''"
            :tabindex="tabindex"
            @blur="isShowAgeUpper = false"
          >
            <div
              class="drop-down d-flex align-items-center"
              @click="isShowAgeUpper = !isShowAgeUpper"
            >
              <span class="score__text">{{ ageUpper }}</span>
            </div>
            <div class="position-absolute score-option">
              <div
                class="score-option__items"
                v-for="(age, index) in listAgeUpper"
                :class="ageUpper == listAgeUpper[index] ? 'active' : ''"
                :key="index"
                @click="chooseAgeUpper(index)"
              >
                {{ listAgeUpper[index] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <label for="" class="f-w6 label">タバコ</label>
      <div class="d-flex flex-wrap">
        <div
          class="tabaco d-flex align-items-center"
          v-for="(tabaco, index) in commonTutorials.cigarettes"
          :key="index"
        >
          <div
            class="
              tabaco-checkbox
              d-flex
              justify-content-center
              align-items-center
            "
            :class="
              tabacoOption.indexOf(
                commonTutorials.cigarettes[index].cigarette_id
              ) > -1
                ? 'active'
                : ''
            "
            @click="
              chooseTabaco(commonTutorials.cigarettes[index].cigarette_id)
            "
          >
            <b-icon
              icon="check"
              v-if="
                tabacoOption.indexOf(
                  commonTutorials.cigarettes[index].cigarette_id
                ) > -1
              "
            ></b-icon>
          </div>
          {{ tabaco.name }}
        </div>
      </div>
      <label for="" class="f-w6 label">プレー中の飲酒</label>
      <div class="d-flex flex-wrap">
        <div
          class="tabaco d-flex align-items-center"
          v-for="(play, index) in commonTutorials.alcohols"
          :key="index"
        >
          <div
            class="
              tabaco-checkbox
              d-flex
              justify-content-center
              align-items-center
            "
            :class="
              playOption.indexOf(commonTutorials.alcohols[index].alcohol_id) >
              -1
                ? 'active'
                : ''
            "
            @click="choosePlay(commonTutorials.alcohols[index].alcohol_id)"
          >
            <b-icon
              icon="check"
              v-if="
                playOption.indexOf(commonTutorials.alcohols[index].alcohol_id) >
                  -1
              "
            ></b-icon>
          </div>
          {{ play.name }}
        </div>
      </div>
      <label for="" class="f-w6 label">集合方法</label>
      <div class="d-flex">
        <div
          class="tabaco d-flex align-items-center"
          v-for="(meeting, index) in meeting"
          :key="index"
        >
          <div
            class="
              tabaco-checkbox
              d-flex
              justify-content-center
              align-items-center
            "
            :class="meetingOption == meeting.id ? 'active' : ''"
            @click="chooseMeeting(meeting.id)"
          >
            <b-icon icon="check" v-if="meetingOption == meeting.id"></b-icon>
          </div>
          {{ meeting.name }}
        </div>
      </div>
      <div class="divide d-flex align-items-center justify-content-between">
        <div class="divide--left" />
        キャスト スコア
        <div class="divide--right" />
      </div>
      <div class="best-score">
        <label class="best-score-label f-w6" for="">ベストスコア</label>
        <div class="d-flex justify-content-between align-items-center">
          <div
            class="score position-relative"
            :class="isShowBestScoreLower ? 'active' : ''"
            :tabindex="tabindex"
            @blur="isShowBestScoreLower = false"
          >
            <div
              class="drop-down d-flex align-items-center"
              @click="isShowBestScoreLower = !isShowBestScoreLower"
            >
              <span class="score__text">{{ bestScoreLower }}</span>
            </div>
            <div class="position-absolute score-option">
              <div
                class="score-option__items"
                v-for="(bestScore, index) in listBestScoreLower"
                :class="
                  bestScoreLower == listBestScoreLower[index] ? 'active' : ''
                "
                :key="index"
                @click="chooseBestScoreLower(index)"
              >
                {{ listBestScoreLower[index] }}
              </div>
            </div>
          </div>
          <span class="f-w6 score__span">~</span>
          <div
            class="score position-relative"
            :class="isShowBestScoreUpper ? 'active' : ''"
            :tabindex="tabindex"
            @blur="isShowBestScoreUpper = false"
          >
            <div
              class="drop-down d-flex align-items-center"
              @click="isShowBestScoreUpper = !isShowBestScoreUpper"
            >
              <span class="score__text">{{ bestScoreUpper }}</span>
            </div>
            <div class="position-absolute score-option">
              <div
                class="score-option__items"
                v-for="(bestScore, index) in listBestScoreUpper"
                :class="
                  bestScoreUpper == listBestScoreUpper[index] ? 'active' : ''
                "
                :key="index"
                @click="chooseBestScoreUpper(index)"
              >
                {{ listBestScoreUpper[index] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="avg-score">
        <label class="best-score-label f-w6" for="">アベレージスコア</label>
        <div class="d-flex justify-content-between align-items-center">
          <div
            class="score position-relative"
            :class="isShowAvgScoreLower ? 'active' : ''"
            :tabindex="tabindex"
            @blur="isShowAvgScoreLower = false"
          >
            <div
              class="drop-down d-flex align-items-center"
              @click="isShowAvgScoreLower = !isShowAvgScoreLower"
            >
              <span class="score__text">{{ avgScoreLower }}</span>
            </div>
            <div class="position-absolute score-option">
              <div
                class="score-option__items"
                v-for="(avgScore, index) in listAvgScoreLower"
                :class="
                  avgScoreLower == listAvgScoreLower[index] ? 'active' : ''
                "
                :key="index"
                @click="chooseAvgScoreLower(index)"
              >
                {{ listAvgScoreLower[index] }}
              </div>
            </div>
          </div>
          <span class="f-w6 score__span">~</span>
          <div
            class="score position-relative"
            :class="isShowAvgScoreUpper ? 'active' : ''"
            :tabindex="tabindex"
            @blur="isShowAvgScoreUpper = false"
          >
            <div
              class="drop-down d-flex align-items-center"
              @click="isShowAvgScoreUpper = !isShowAvgScoreUpper"
            >
              <span class="score__text">{{ avgScoreUpper }}</span>
            </div>
            <div class="position-absolute score-option">
              <div
                class="score-option__items"
                v-for="(avgScore, index) in listAvgScoreUpper"
                :class="
                  avgScoreUpper == listAvgScoreUpper[index] ? 'active' : ''
                "
                :key="index"
                @click="chooseAvgScoreUpper(index)"
              >
                {{ listAvgScoreUpper[index] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="divide-max"></div>
      <div class="golf-history">
        <label class="best-score-label f-w6" for="">ゴルフ歴</label>
        <div class="d-flex justify-content-between align-items-center">
          <div
            class="score position-relative"
            :class="isShowHistoryLower ? 'active' : ''"
            :tabindex="tabindex"
            @blur="isShowHistoryLower = false"
          >
            <div
              class="drop-down d-flex align-items-center"
              @click="isShowHistoryLower = !isShowHistoryLower"
            >
              <span class="score__text" v-if="historyLower != null">{{
                commonTutorials.golfExperiences[historyLower].name
              }}</span>
            </div>
            <div
              class="position-absolute score-option score-option-golf-history"
            >
              <div
                class="score-option__items"
                v-for="(history, index) in commonTutorials.golfExperiences"
                :class="historyLower == index ? 'active' : ''"
                :key="index"
                @click="chooseHistoryLower(index)"
              >
                {{ commonTutorials.golfExperiences[index].name }}
              </div>
            </div>
          </div>
          <span class="f-w6 score__span">~</span>
          <div
            class="score position-relative"
            :class="isShowHistoryUpper ? 'active' : ''"
            :tabindex="tabindex"
            @blur="isShowHistoryUpper = false"
          >
            <div
              class="drop-down d-flex align-items-center"
              @click="isShowHistoryUpper = !isShowHistoryUpper"
            >
              <span class="score__text" v-if="historyUpper != null">{{
                commonTutorials.golfExperiences[historyUpper].name
              }}</span>
            </div>
            <div
              class="position-absolute score-option score-option-golf-history"
            >
              <div
                class="score-option__items"
                v-for="(history, index) in commonTutorials.golfExperiences"
                :class="historyUpper == index ? 'active' : ''"
                :key="index"
                @click="chooseHistoryUpper(index)"
              >
                {{ commonTutorials.golfExperiences[index].name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-btn">
        <button class="btn-submit" @click="searchUser()" type="submit">
          検索する
        </button>
        <div class="blur-filter" />
      </div>
    </template>
  </div>
</template>

<script>
import SelectMultiFilterCast from "@/views/Cast/MultiSelectFilter";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isShowAvgScoreUpper: false,
      isShowAvgScoreLower: false,
      isShowBestScoreUpper: false,
      isShowBestScoreLower: false,
      isShowAgeUpper: false,
      isShowAgeLower: false,
      isShowProvince: false,
      isShowHistoryLower: false,
      isShowHistoryUpper: false,
      ageLower: 20,
      ageUpper: 100,
      province: null,
      bestScoreUpper: "160以上",
      bestScoreLower: 60,
      avgScoreLower: 60,
      avgScoreUpper: "180以上",
      indexBestScoreLower: 0,
      indexBestScoreUpper: 101,
      indexAvgScoreLower: 0,
      indexAvgScoreUpper: 25,
      historyLower: null,
      historyUpper: null,
      listHistoryLower: [],
      listHistoryUpper: [],
      listAgeLower: [],
      listAgeUpper: [],
      listAvgScoreLower: [],
      listAvgScoreUpper: [],
      listBestScoreLower: [],
      listBestScoreUpper: [],
      playOption: [],
      tabacoOption: [],
      meetingOption: null,
      play: [{ name: "飲酒可" }, { name: "飲酒不可" }, { name: "どちらでも" }],
      meeting: [
        { name: "ピックアップ", id: "1" },
        { name: "現地集合", id: "2" }
      ],
      show: false,
      isShowArea: false,
      areaValue: [],
      selectedArea: [],
      listArea: []
    };
  },

  computed: {
    ...mapGetters({
      commonTutorials: "common/commonTutorials",
      isLoading: "common/isLoading"
    })
  },

  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    await this.$store.dispatch("common/commonsTutorials", {
      array_common_request: [
        "prefectures",
        "residences",
        "occupations",
        "golfExperiences",
        "golfAreas",
        "golfFrequencies",
        "cigarettes",
        "alcohols",
        "annualIncomes",
        "relationships",
        "appearances",
        "figures",
        "otherPreferences"
      ]
    });
    this.setScore();
    this.historyLower = 0;
    this.historyUpper = 10;
    this.$store.dispatch("common/setIsLoading", false);
  },

  components: {
    SelectMultiFilterCast
  },
  props: {
    tabindex: {
      type: Number,
      require: null,
      default: 0
    },

    type: Number
  },
  methods: {
    setScore() {
      for (let i = 60; i <= 160; i++) {
        this.listBestScoreLower.push(i);
        this.listBestScoreUpper.push(i);
      }
      this.listBestScoreLower.push("160以上");
      this.listBestScoreUpper.push("160以上");
      for (let i = 60; i <= 180; i += 5) {
        this.listAvgScoreLower.push(i);
        this.listAvgScoreUpper.push(i);
      }
      this.listAvgScoreLower.push("180以上");
      this.listAvgScoreUpper.push("180以上");
      for (let i = 20; i <= 100; i++) {
        this.listAgeLower.push(i);
        this.listAgeUpper.push(i);
      }
      for (let i = 1; i <= 10; i++) {
        this.listHistoryLower.push(i);
        this.listHistoryUpper.push(i);
      }
    },
    chooseTabaco(index) {
      if (this.tabacoOption.indexOf(index) === -1) {
        this.tabacoOption.push(index);
      } else {
        const i = this.tabacoOption.indexOf(index);
        this.tabacoOption.splice(i, 1);
      }
    },
    choosePlay(index) {
      if (this.playOption.indexOf(index) === -1) {
        this.playOption.push(index);
      } else if (this.playOption.indexOf(index) > -1) {
        const i = this.playOption.indexOf(index);
        this.playOption.splice(i, 1);
      }
    },
    chooseMeeting(index) {
      if (this.meetingOption != index) {
        this.meetingOption = index;
      } else {
        this.meetingOption = null;
      }
    },
    chooseAvgScoreLower(index) {
      this.avgScoreLower = this.listAvgScoreLower[index];
      this.indexAvgScoreLower = index;
      if (this.indexBestScoreUpper < this.indexAvgScoreLower) {
        this.avgScoreUpper = null;
      }
      this.isShowAvgScoreLower = false;
    },
    chooseAvgScoreUpper(index) {
      this.avgScoreUpper = this.listAvgScoreUpper[index];
      this.indexBestScoreUpper = index;
      if (this.indexBestScoreUpper < this.indexAvgScoreLower) {
        this.avgScoreLower = null;
      }
      this.isShowAvgScoreUpper = false;
    },
    chooseBestScoreLower(index) {
      this.bestScoreLower = this.listBestScoreLower[index];
      this.indexBestScoreLower = index;
      if (this.indexBestScoreUpper < this.indexBestScoreLower) {
        this.bestScoreUpper = null;
      }
      this.isShowBestScoreLower = false;
    },
    chooseBestScoreUpper(index) {
      this.bestScoreUpper = this.listBestScoreUpper[index];
      this.indexBestScoreUpper = index;
      if (this.indexBestScoreUpper < this.indexBestScoreLower) {
        this.bestScoreLower = null;
      }
      this.isShowBestScoreUpper = false;
    },
    chooseAgeLower(index) {
      this.ageLower = this.listAgeLower[index];
      if (this.ageUpper < this.ageLower) {
        this.ageUpper = null;
      }
      this.listAgeUpper = [];
      for (let i = this.ageLower; i <= 100; i++) {
        this.listAgeUpper.push(i);
      }
      this.isShowAgeLower = false;
    },
    chooseAgeUpper(index) {
      this.ageUpper = this.listAgeUpper[index];
      if (this.ageUpper < this.ageLower) {
        this.ageLower = null;
      }
      this.listAgeLower = [];
      for (let i = 20; i <= this.ageUpper; i++) {
        this.listAgeLower.push(i);
      }
      this.isShowAgeUpper = false;
    },
    chooseHistoryLower(index) {
      this.historyLower = index;
      if (this.historyLower > this.historyUpper) {
        this.historyUpper = null;
      }
      this.isShowHistoryLower = false;
    },
    chooseHistoryUpper(index) {
      this.historyUpper = index;
      if (this.historyLower > this.historyUpper) {
        this.historyLower = null;
      }
      this.isShowHistoryUpper = false;
    },

    searchUser() {
      var golf_area = [];
      for (let i = 0; i < this.areaValue.length; i++) {
        golf_area.push(
          this.commonTutorials.prefectures[this.areaValue[i]].prefecture_id
        );
      }
      let perpage = 25;
      if (window.innerWidth < 992) {
        perpage = 8;
      }
      const params = {
        type: this.$route.query.type ? this.$route.query.type : 1,
        page: 1,
        perpage: perpage,
        age_lower: this.ageLower,
        age_upper: this.ageUpper,
        prefecture_ids: golf_area.length >= 1 ? golf_area.toString() : null,
        best_score_upper:
          this.bestScoreUpper != "160以上" ? this.bestScoreUpper : 999,
        best_score_lower:
          this.bestScoreLower != "160以上" ? this.bestScoreLower : 999,
        avg_score_lower:
          this.avgScoreLower != "180以上" ? this.avgScoreLower : 999,
        avg_score_upper:
          this.avgScoreUpper != "180以上" ? this.avgScoreUpper : 999,
        golf_experience_id_lower:
          this.historyLower != null
            ? this.commonTutorials.golfExperiences[this.historyLower]
                .golf_experience_id
            : null,
        golf_experience_id_upper:
          this.historyUpper != null
            ? this.commonTutorials.golfExperiences[this.historyUpper]
                .golf_experience_id
            : null,
        alcohol_ids:
          this.playOption.length >= 1 ? this.playOption.toString() : null,
        cigarette_ids:
          this.tabacoOption.length >= 1 ? this.tabacoOption.toString() : null,
        meeting_method: this.meetingOption
      };
      this.$emit("searchMen", params);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/filterMenPopup.scss";
</style>
